import { Container, Grid, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Header = ({ msLogo }: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div
      style={{
        position: "absolute",
        paddingTop: "20px",
        width: "100%",
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          justifyContent:"space-between"
        }}
      >
        <img
          src={msLogo}
          alt=""
          style={{
            alignItems: "start",
            display: "flex",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        />
        <Button
          sx={{
            backgroundColor: "#0069FF",
            borderRadius: "14px",
            padding: "10px 22px",
            border: "1px solid #0069FF",
            fontFamily: "Red Hat Display",
            color: "#FFFFFF",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "21px",
            textTransform: "none",
            display:{xs:"none",sm:"none",md:"flex"}
          }}
          onClick={() => navigate("/investor-sign-up")}
        >
          Become Investor
        </Button>
      </Container>
    </div>
  );
};

export default Header;
