import { BlueDna, BlueEye, BlueHearth, BlueSocial } from "../assets";

export const holisticItemList = [
  {
    icon: BlueHearth,
    text1: `Physical`,
    text2: "Health",
  },
  {
    icon: BlueDna,
    text1: "Mental and",
    text2: "Emotional Health",
  },
  {
    icon: BlueEye,
    text1: "Spiritual",
    text2: "Health",
  },
  {
    icon: BlueSocial,
    text1: "Social",
    text2: "Health",
  },
];

export const visibleDamages = [
  "Skin problems, such as acne, psoriasis, and eczema",
  "Hair loss or thinning",
  "Digestive issues, such as irritable bowel syndrome (IBS), acid reflux, and stomach ulcers",
  "Teeth grinding and jaw clenching, which can lead to dental problems and headaches",
  "Muscle tension and pain, including neck and back pain",
  "Sleep disturbances, such as insomnia or nightmares",
  "Increased susceptibility to illness and infections",
  "Impaired wound healing, and more.",
];

export const invisibleDamages = [
  "High blood pressure and increased risk of heart disease",
  "Chronic inflammation and increased risk of autoimmune disorders",
  "Impaired immune system function",
  "Mental health disorders, such as anxiety and depression",
  "Cognitive decline and increased risk of dementia",
  "Increased risk of obesity and diabetes",
  "Reduced fertility and sexual dysfunction, and more.",
];

export const userService = [
  "Access to diverse holistic health professionals.",
  "Tailored individual and group sessions.",
  "Option to choose multilingual professionals.",
  "Flexible scheduling with reminders.",
  "Integrated video, chat, and voice tools.",
  "End-to-end data encryption.",
  "Exclusive holistic health resources.",
  "Secure online payment gateway.",
  "Interactive progress dashboard.",
  "Pioneer Member Badge for early registrants, and more.",
];

export const consultantService = [
  "Personalized profile page.",
  "Comprehensive client management dashboard.",
  "Connect with a global user bases. HIPAA & GDPR compliant communication tools.",
  "Advanced data encryption.",
  "Specialized assessment (survey, feedback,etc.) tools.",
  "User-friendly scheduling interface.",
  "Secure online transactions.",
  "Peer networking opportunities.",
  "Access to training resources and workshops, and more.",
];

export const signUpRoles = ["Customer", "Consultant"];

export const professionsBulk = [
  "Nutritionist",
  "Psychologist",
  "Social Life Coach",
  "Yoga Instructor",
  "Pilates Instructor",
  "Meditation Instructor",
];

export const languagesBulk = [
  "Turkish",
  "English",
  "Spanish",
  "French",
  "Russian",
];

export const documentUrlList = {
  hipaa:
    "https://minimum-stress-documents-space.nyc3.cdn.digitaloceanspaces.com/Minimum%20Stress%20LLC%20-%20HIPAA%20Notice%20V1.pdf",
  privacyPolicy:
    "https://minimum-stress-documents-space.nyc3.cdn.digitaloceanspaces.com/Minimum%20Stress%20LLC%20-%20Privacy%20Policy%20V1.pdf",
  termOfUs:
    "https://minimum-stress-documents-space.nyc3.cdn.digitaloceanspaces.com/Minimum%20Stress%20LLC%20-%20Terms%20of%20Use%20V1.pdf",
};
