import { Grid, Typography, Container } from "@mui/material";
import { WhyMsPattern, YogaWhy } from "../assets";

const WhyMs = () => {
  return (
    <div
      style={{
        backgroundColor: "#1E293B",
        backgroundImage: `url(${WhyMsPattern})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="lg"
        style={{ paddingTop: "100px", paddingBottom: "80px" }}
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} columnSpacing={8}>
          <Grid item xs={4} sm={8} md={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "32px",
                  height: "2px",
                  backgroundColor: "#FF6155",
                  marginRight: "4px",
                }}
              ></div>
              <Typography
                style={{
                  fontFamily: "Red Hat Display",
                  color: "#FF6155",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "100%",
                }}
              >
                Minimize Your Stress
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#F9F9F9",
                  fontSize: { xs: "40px", sm: "40px", md: "52px" },
                  fontWeight: 400,
                  lineHeight: "110%",
                  marginRight: "5px",
                }}
              >
                Why
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#F9F9F9",
                  fontSize: { xs: "40px", sm: "40px", md: "52px" },
                  fontWeight: 700,
                  lineHeight: "110%",
                }}
              >
                Minimum Stress?
              </Typography>
            </div>
            <Typography
              sx={{
                fontFamily: "Red Hat Display",
                color: "#F1F5F9",
                fontSize: { xs: "16px", sm: "16px", md: "18px" },
                fontWeight: 500,
                lineHeight: "150%",
                textAlign: "start",
                marginTop: "24px",
              }}
            >
              Minimum Stress is more than just a name; it's a philosophy.
              Holistic health interlinks the physical, mental, emotional,
              social, and spiritual aspects of our lives. By addressing the
              complexities and stressors of modern living, our goal is to foster
              a sense of equilibrium and inner tranquility for all. <br />
              <br />
              Our platform stands as a hub, seamlessly connecting individuals
              with a diverse range of holistic health professionals. We've
              created an expansive ecosystem where users can interact with a
              myriad of experts, all under one digital roof.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{ display: "flex", marginTop: { sm: "20px", xs: "20px" } }}
          >
            <img src={YogaWhy} alt="" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default WhyMs;
