import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import InputScreenLeftSide from "../components/inputScreenLeftSide";
import { InputAdornment } from "@mui/material";
import { InputMail, InputUser, InvestorSign } from "../assets";
import { MuiTelInput } from "mui-tel-input";
import { useState, useEffect } from "react";
import { IInvestor } from "../utils/customModels";
import CommonLoading from "../components/commonLoading";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CommonAlert from "../components/commonAlert";
import { documentUrlList } from "../utils/constants";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const defaultTheme = createTheme();

const InvestorSignUp = () => {
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    ReactGA.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location])

  const [investorInput, setInvestorInput] = useState<IInvestor>({
    name: "",
    surname: "",
    email: "",
    phone: "",
    message: "",
    overAgeEighteen: false,
    tosAcceptance: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    let phone = {
      countryCode: "",
      number: "",
    };

    try {
      phone = {
        countryCode: investorInput.phone.split(" ")[0],
        number: investorInput.phone
          .slice(investorInput.phone.split(" ")[0].length)
          .replaceAll(" ", ""),
      };
    } catch (error) {}

    try {
      const investorResponse = await axios.post(
        `https://backend-zl83w.ondigitalocean.app/waitlist/create`,
        {
          name: investorInput.name,
          surname: investorInput.surname,
          email: investorInput.email,
          type: "investor",
          phone: phone,
          tosAcceptance: investorInput.tosAcceptance,
          overAgeOfEighteen: investorInput.overAgeEighteen,
        }
      );
      if (investorResponse.data.isRequestSuccessful) {
        navigate(`/verification/${investorResponse.data.data._id}`);
      } else {
        setAlert(true);
      }
    } catch (error) {
      setAlert(true);
    }
    setLoading(false);
  };

  const buttonDisableDecider = () => {
    if (
      investorInput.name.trim().length < 2 ||
      investorInput.surname.trim().length < 2 ||
      !investorInput.email.includes("@") ||
      investorInput.phone.length < 6 ||
      !investorInput.tosAcceptance ||
      !investorInput.overAgeEighteen
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CommonLoading loading={loading} />
      <CommonAlert
        _open={alert}
        _onClose={() => setAlert(false)}
        _message="Something went wrong. Contact with us!"
        _type="warning"
      />
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <InputScreenLeftSide
            image={InvestorSign}
            title="Invest in Growth"
            description="Join us in shaping the future of holistic health and be a part of a transformative journey towards wellness and innovation."
          />
        </Grid>
        <Grid item xs={4} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: { xs: 6, sm: 6, md: 18 },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ marginTop: "24px", textAlign: "start" }}>
              <Typography
                style={{
                  fontFamily: "Red Hat Display",
                  color: "#1E293B",
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "26px",
                }}
              >
                Become Investor
              </Typography>
              <Typography
                style={{
                  fontFamily: "Red Hat Display",
                  color: "#1E293B",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "21px",
                  marginTop: "8px",
                  marginBottom: "20px",
                }}
              >
                We'd love to hear from you. Please briefly share yourself, what
                we can do together, and the ideas you have in mind. We also
                have big dreams.
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                placeholder="Name"
                value={investorInput.name}
                onChange={(e: any) =>
                  setInvestorInput({
                    ...investorInput,
                    name: e.target.value,
                  })
                }
                autoFocus
                sx={{
                  "& fieldset": {
                    border: "1px solid #E2E8F0",
                    borderRadius: "12px",
                  },
                  input: {
                    color: "#94A3B8",
                    fontFamily: "Red Hat Display",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={InputUser}
                        alt=""
                        style={{ width: "24px", height: "24px" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="surname"
                placeholder="Surname"
                name="surname"
                value={investorInput.surname}
                onChange={(e: any) =>
                  setInvestorInput({
                    ...investorInput,
                    surname: e.target.value,
                  })
                }
                autoFocus
                sx={{
                  "& fieldset": {
                    border: "1px solid #E2E8F0",
                    borderRadius: "12px",
                  },
                  input: {
                    color: "#94A3B8",
                    fontFamily: "Red Hat Display",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={InputUser}
                        alt=""
                        style={{ width: "24px", height: "24px" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email"
                value={investorInput.email}
                onChange={(e: any) =>
                  setInvestorInput({ ...investorInput, email: e.target.value })
                }
                name="email"
                autoFocus
                sx={{
                  "& fieldset": {
                    border: "1px solid #E2E8F0",
                    borderRadius: "12px",
                  },
                  input: {
                    color: "#94A3B8",
                    fontFamily: "Red Hat Display",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={InputMail}
                        alt=""
                        style={{ width: "24px", height: "24px" }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <MuiTelInput
                label="Phone"
                fullWidth
                style={{ marginTop: "15px" }}
                defaultCountry="US"
                value={investorInput.phone}
                onChange={(newValue, info) => {
                  setInvestorInput({ ...investorInput, phone: newValue });
                }}
              />
              <TextField
                margin="normal"
                multiline
                rows={5}
                required
                fullWidth
                id="message"
                placeholder="Your Message"
                value={investorInput.message}
                onChange={(e: any) =>
                  setInvestorInput({
                    ...investorInput,
                    message: e.target.value,
                  })
                }
                name="message"
                autoFocus
                sx={{
                  "& fieldset": {
                    border: "1px solid #E2E8F0",
                    borderRadius: "12px",
                  },
                  input: {
                    color: "#94A3B8",
                    fontFamily: "Red Hat Display",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                  },
                }}
              />
              <div style={{ display: "flex" }}>
                <Checkbox
                  value={investorInput.tosAcceptance}
                  required
                  id="check"
                  onChange={(e: any) =>
                    setInvestorInput({
                      ...investorInput,
                      tosAcceptance: e.target.checked,
                    })
                  }
                />
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#64748B",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    marginTop: "12px",
                  }}
                >
                  By creating an account means you agree to the{" "}
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontWeight: 700,
                      fontSize: "12px",
                      fontFamily: "Red Hat Display",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    component="b"
                    onClick={() =>
                      window.open(documentUrlList.termOfUs, "_blank")
                    }
                  >
                    Terms & Conditions
                  </Typography>{" "}
                  and our{" "}
                  <Typography
                    style={{
                      color: "#0F172A",
                      fontWeight: 700,
                      fontSize: "12px",
                      fontFamily: "Red Hat Display",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    component="b"
                    onClick={() =>
                      window.open(documentUrlList.privacyPolicy, "_blank")
                    }
                  >
                    Privacy Policy
                  </Typography>
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  value={investorInput.overAgeEighteen}
                  required
                  id="check"
                  onChange={(e: any) =>
                    setInvestorInput({
                      ...investorInput,
                      overAgeEighteen: e.target.checked,
                    })
                  }
                />
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#64748B",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    marginTop: "12px",
                  }}
                >
                  I am older than 18 years old
                </Typography>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  pt: 2,
                  pb: 2,
                  backgroundColor: "#0069FF",
                  color: "#FFFFFF",
                  fontFamily: "Red Hat Display",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "150%",
                  borderRadius: "16px",
                  textTransform: "none",
                }}
                disabled={buttonDisableDecider()}
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => navigate("/")}
              >
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#64748B",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "150%",
                    cursor: "pointer",
                  }}
                >
                  Back Home
                </Typography>
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default InvestorSignUp;
