import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import InputScreenLeftSide from "../components/inputScreenLeftSide";
import {
  CloudUpload,
  CustomerSign,
  InputLock,
  InputMail,
  InputUser,
  Language,
  Profession,
  SignUpImg,
} from "../assets";
import { useState, useEffect } from "react";
import { InputAdornment } from "@mui/material";
import SignUpToggle from "../components/signUpToggle";
import {
  documentUrlList,
  languagesBulk,
  professionsBulk,
  signUpRoles,
} from "../utils/constants";
import LanguagesJson from "../utils/LanguageJson.json";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { IConsultant, ICustomer } from "../utils/customModels";
import { MuiTelInput } from "mui-tel-input";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import CommonLoading from "../components/commonLoading";
import CommonAlert from "../components/commonAlert";
import ReactGA from "react-ga4";

const defaultTheme = createTheme();

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function SignUp() {
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setFile] = useState<File | undefined>();
  const [currentRole, setCurrentRole] = useState<string>(
    location?.state?.type || "Consultant"
  ); //customer - consultant
  const [customerInput, setCustomerInput] = useState<ICustomer>({
    name: "",
    surname: "",
    email: "",
    password: "",
    tosAcceptance: false,
    overAgeEighteen: false,
  });
  const [consultantInput, setConsultantInput] = useState<IConsultant>({
    name: "",
    surname: "",
    email: "",
    profession: "",
    language: "",
    phone: "",
    tosAcceptance: false,
    overAgeEighteen: false,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);

  useEffect(() => {
    ReactGA.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location])

  const handleConsultantSubmit = async () => {
    setLoading(true);
    let phone = {
      countryCode: "",
      number: "",
    };
    let uploadedFile = null;
    try {
      var formData = new FormData();
      formData.append("file", file as any);
      const { data } = await axios.post(
        `https://backend-zl83w.ondigitalocean.app/upload/single`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      uploadedFile = data;
    } catch (error) {}

    if (uploadedFile !== null) {
      try {
        phone = {
          countryCode: consultantInput.phone.split(" ")[0],
          number: consultantInput.phone
            .slice(consultantInput.phone.split(" ")[0].length)
            .replaceAll(" ", ""),
        };
      } catch (error) {}

      try {
        const consultantResponse = await axios.post(
          `https://backend-zl83w.ondigitalocean.app/waitlist/create`,
          {
            name: consultantInput.name,
            surname: consultantInput.surname,
            email: consultantInput.email,
            type: "consultant",
            profession: consultantInput.profession,
            language: consultantInput.language,
            uploadedCv: uploadedFile._id,
            phone: phone,
            tosAcceptance: consultantInput.tosAcceptance,
            overAgeOfEighteen: consultantInput.overAgeEighteen,
          }
        );
        if (consultantResponse.data.isRequestSuccessful) {
          navigate(`/verification/${consultantResponse.data.data._id}`);
        } else {
          setAlert(true);
        }
      } catch (error) {
        setAlert(true);
      }
    } else {
      setAlert(true);
    }

    setLoading(false);
  };

  const handleCustomerSubmit = async () => {
    setLoading(true);

    try {
      const customerResponse = await axios.post(
        `https://backend-zl83w.ondigitalocean.app/waitlist/create`,
        {
          ...customerInput,
          type: "customer",
        }
      );
      if (customerResponse.data.isRequestSuccessful) {
        navigate(`/verification/${customerResponse.data.data._id}`);
      } else {
        setAlert(true);
      }
    } catch (error) {
      setAlert(true);
    }
    setLoading(false);
  };

  const CustomerButtonDisableDecider = () => {
    if (
      customerInput.name.trim().length < 2 ||
      customerInput.surname.trim().length < 2 ||
      !customerInput.email.includes("@") ||
      !customerInput.tosAcceptance ||
      !customerInput.overAgeEighteen
    ) {
      return true;
    } else {
      return false;
    }
  };

  const ConsultantButtonDisableDecider = () => {
    if (
      consultantInput.name.trim().length < 2 ||
      consultantInput.surname.trim().length < 2 ||
      !consultantInput.email.includes("@") ||
      consultantInput.phone.length < 6 ||
      !consultantInput.tosAcceptance ||
      !consultantInput.overAgeEighteen ||
      consultantInput.profession.length <= 0 ||
      consultantInput.language.length <= 0 ||
      typeof file === "undefined"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CommonLoading loading={loading} />
      <CommonAlert
        _open={alert}
        _onClose={() => setAlert(false)}
        _message="Something went wrong. Contact with us!"
        _type="warning"
      />
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <InputScreenLeftSide
            image={currentRole == "Customer" ? CustomerSign : SignUpImg}
            title="Expand Your Horizon!"
            description="Discover our innovative platform, offering advanced technology for
            seamless and secure session management Join our community of
            professionals and lead your Holistic Health Practice with confidence
            and convenience"
          />
        </Grid>
        <Grid item xs={4} sm={8} md={6} component={Paper} elevation={6} square>
          {currentRole == "Customer" ? (
            <Box
              sx={{
                my: 8,
                mx: { xs: 6, sm: 6, md: 18 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100%",
                }}
              >
                <SignUpToggle
                  selectedRole={currentRole}
                  onClick={(e: string) => setCurrentRole(e)}
                />
              </div>
              <Box
                component="form"
                noValidate
                sx={{ marginTop: "24px", textAlign: "start" }}
              >
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#1E293B",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "26px",
                  }}
                >
                  Sign up to join waitlist!
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#1E293B",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    marginTop: "8px",
                    marginBottom: "20px",
                  }}
                >
                  Sign up today and discover the benefits of working with a team{" "}
                  <br /> dedicated to your overall well-being!
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  placeholder="Name"
                  name="name"
                  onChange={(e) =>
                    setCustomerInput((val) => ({
                      ...val,
                      name: e.target.value,
                    }))
                  }
                  autoFocus
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputUser}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="surname"
                  placeholder="Surname"
                  name="surname"
                  onChange={(e) =>
                    setCustomerInput((val) => ({
                      ...val,
                      surname: e.target.value,
                    }))
                  }
                  autoFocus
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputUser}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  placeholder="Email"
                  name="email"
                  autoFocus
                  onChange={(e) =>
                    setCustomerInput((val) => ({
                      ...val,
                      email: e.target.value,
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputMail}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  placeholder="Password"
                  name="password"
                  autoFocus
                  onChange={(e) =>
                    setCustomerInput((val) => ({
                      ...val,
                      password: e.target.value,
                    }))
                  }
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputLock}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#64748B",
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    marginBottom: "25px",
                  }}
                >
                  Your password must have at least 8 characters
                </Typography>

                <div style={{ display: "flex" }}>
                  <Checkbox
                    value="remember"
                    color="primary"
                    required
                    id="check"
                    name="check"
                    onChange={(e) =>
                      setCustomerInput((val) => ({
                        ...val,
                        tosAcceptance: e.target.checked,
                      }))
                    }
                  />
                  <Typography
                    style={{
                      fontFamily: "Red Hat Display",
                      color: "#64748B",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      marginTop: "12px",
                    }}
                  >
                    By creating an account means you agree to the{" "}
                    <Typography
                      style={{
                        color: "#0F172A",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "Red Hat Display",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      component="b"
                      onClick={() =>
                        window.open(documentUrlList.termOfUs, "_blank")
                      }
                    >
                      Terms & Conditions
                    </Typography>{" "}
                    and our{" "}
                    <Typography
                      style={{
                        color: "#0F172A",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "Red Hat Display",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      component="b"
                      onClick={() =>
                        window.open(documentUrlList.privacyPolicy, "_blank")
                      }
                    >
                      Privacy Policy
                    </Typography>
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    value={customerInput.overAgeEighteen}
                    required
                    id="check"
                    onChange={(e) =>
                      setCustomerInput({
                        ...customerInput,
                        overAgeEighteen: e.target.checked,
                      })
                    }
                  />
                  <Typography
                    style={{
                      fontFamily: "Red Hat Display",
                      color: "#64748B",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      marginTop: "12px",
                    }}
                  >
                    I am older than 18 years old
                  </Typography>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    pt: 2,
                    pb: 2,
                    backgroundColor: "#0069FF",
                    color: "#FFFFFF",
                    fontFamily: "Red Hat Display",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "150%",
                    borderRadius: "16px",
                    textTransform: "none",
                  }}
                  disabled={CustomerButtonDisableDecider()}
                  onClick={() => handleCustomerSubmit()}
                >
                  Sign Up
                </Button>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => navigate("/")}
                >
                  <Typography
                    style={{
                      fontFamily: "Red Hat Display",
                      color: "#64748B",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "150%",
                      cursor: "pointer",
                    }}
                  >
                    Back Home
                  </Typography>
                </div>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                my: 8,
                mx: { xs: 6, sm: 6, md: 18 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  width: "100%",
                }}
              >
                <SignUpToggle
                  selectedRole={currentRole}
                  onClick={(e: string) => setCurrentRole(e)}
                />
              </div>
              <Box
                component="form"
                noValidate
                sx={{ marginTop: "24px", textAlign: "start" }}
              >
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#1E293B",
                    fontSize: "24px",
                    fontWeight: 700,
                    lineHeight: "26px",
                  }}
                >
                  Sign up to join waitlist!
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#1E293B",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                    marginTop: "8px",
                    marginBottom: "20px",
                  }}
                >
                  Together, let's transform lives and promote well-being!
                </Typography>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  value={consultantInput.name}
                  placeholder="Name"
                  name="name"
                  autoFocus
                  onChange={(e) =>
                    setConsultantInput({
                      ...consultantInput,
                      name: e.target.value,
                    })
                  }
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputUser}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="surname"
                  placeholder="Surname"
                  name="surname"
                  value={consultantInput.surname}
                  autoFocus
                  onChange={(e) =>
                    setConsultantInput({
                      ...consultantInput,
                      surname: e.target.value,
                    })
                  }
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputUser}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  placeholder="Email"
                  name="email"
                  onChange={(e) =>
                    setConsultantInput({
                      ...consultantInput,
                      email: e.target.value,
                    })
                  }
                  autoFocus
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={InputMail}
                          alt=""
                          style={{ width: "24px", height: "24px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <Select
                  id="profession"
                  placeholder="Profession"
                  fullWidth
                  autoFocus
                  displayEmpty
                  value={consultantInput.profession}
                  onChange={(e) =>
                    setConsultantInput({
                      ...consultantInput,
                      profession: e.target.value,
                    })
                  }
                  renderValue={(value) => (
                    <div style={{ display: "flex" }}>
                      <img
                        src={Profession}
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "10px",
                        }}
                      />
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "26px",
                          color: "#9CA4AB",
                          opacity: value ? 1 : 0.6,
                        }}
                      >
                        {value || "Profession"}
                      </Typography>
                    </div>
                  )}
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                    marginTop: "15px",
                  }}
                >
                  {professionsBulk.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "22px",
                          color: "#9CA4AB",
                        }}
                      >
                        {item}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  id="language"
                  placeholder="Language"
                  fullWidth
                  autoFocus
                  displayEmpty
                  value={consultantInput.language}
                  onChange={(e) =>
                    setConsultantInput({
                      ...consultantInput,
                      language: e.target.value,
                    })
                  }
                  renderValue={(value) => (
                    <div style={{ display: "flex" }}>
                      <img
                        src={Language}
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "10px",
                        }}
                      />
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "26px",
                          color: "#9CA4AB",
                          opacity: value ? 1 : 0.6,
                        }}
                      >
                        {value || "Language"}
                      </Typography>
                    </div>
                  )}
                  sx={{
                    "& fieldset": {
                      border: "1px solid #E2E8F0",
                      borderRadius: "12px",
                    },
                    input: {
                      color: "#94A3B8",
                      fontFamily: "Red Hat Display",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "150%",
                    },
                    marginTop: "15px",
                  }}
                >
                  {LanguagesJson.map((item) => (
                    <MenuItem key={item} value={item}>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "22px",
                          color: "#9CA4AB",
                        }}
                      >
                        {item}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <MuiTelInput
                  label="Phone"
                  fullWidth
                  style={{ marginTop: "15px" }}
                  defaultCountry="US"
                  value={consultantInput.phone}
                  onChange={(newValue) => {
                    setConsultantInput((val) => ({
                      ...val,
                      phone: newValue,
                    }));
                  }}
                />
                <div style={{ marginTop: "15px" }}>
                  <Typography
                    style={{
                      fontFamily: "Red Hat Display",
                      fontSize: "12px",
                      fontWeight: "600",
                      lineHeight: "150%",
                      color: "#9CA4AB",
                      display: "block",
                    }}
                  >
                    Submit your resume
                  </Typography>
                  <Button
                    style={{
                      borderRadius: "12px",
                      padding: "8px 16px",
                      textTransform: "capitalize",
                      fontFamily: "Red Hat Display",
                    }}
                    component="label"
                    variant="outlined"
                    startIcon={
                      <img
                        src={CloudUpload}
                        alt=""
                        style={{ width: "24px", height: "24px" }}
                      />
                    }
                  >
                    Choose file
                    <VisuallyHiddenInput
                      onChange={(e) => {
                        setFile(e.target?.files?.[0]);
                      }}
                      type="file"
                    />
                  </Button>
                  {typeof file !== "undefined" ? (
                    <Typography
                      style={{
                        fontFamily: "Red Hat Display",
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "150%",
                        color: "red",
                        display: "block",
                      }}
                    >
                      Uploaded File: {file?.name}
                    </Typography>
                  ) : null}
                </div>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    value="remember"
                    color="primary"
                    required
                    id="check"
                    name="check"
                    onChange={(e) =>
                      setConsultantInput((val) => ({
                        ...val,
                        tosAcceptance: e.target.checked,
                      }))
                    }
                  />
                  <Typography
                    style={{
                      fontFamily: "Red Hat Display",
                      color: "#64748B",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      marginTop: "12px",
                    }}
                  >
                    By creating an account means you agree to the{" "}
                    <Typography
                      style={{
                        color: "#0F172A",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "Red Hat Display",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      component="b"
                      onClick={() =>
                        window.open(documentUrlList.termOfUs, "_blank")
                      }
                    >
                      Terms & Conditions
                    </Typography>{" "}
                    and our{" "}
                    <Typography
                      style={{
                        color: "#0F172A",
                        fontWeight: 700,
                        fontSize: "12px",
                        fontFamily: "Red Hat Display",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      component="b"
                      onClick={() =>
                        window.open(documentUrlList.privacyPolicy, "_blank")
                      }
                    >
                      Privacy Policy
                    </Typography>
                  </Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    value={consultantInput.overAgeEighteen}
                    required
                    id="check"
                    onChange={(e) =>
                      setConsultantInput({
                        ...consultantInput,
                        overAgeEighteen: e.target.checked,
                      })
                    }
                  />
                  <Typography
                    style={{
                      fontFamily: "Red Hat Display",
                      color: "#64748B",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      marginTop: "12px",
                    }}
                  >
                    I am older than 18 years old
                  </Typography>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    pt: 2,
                    pb: 2,
                    backgroundColor: "#0069FF",
                    color: "#FFFFFF",
                    fontFamily: "Red Hat Display",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "150%",
                    borderRadius: "16px",
                    textTransform: "none",
                  }}
                  disabled={ConsultantButtonDisableDecider()}
                  onClick={() => handleConsultantSubmit()}
                >
                  Sign Up
                </Button>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => navigate("/")}
                >
                  <Typography
                    style={{
                      fontFamily: "Red Hat Display",
                      color: "#64748B",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "150%",
                      cursor: "pointer",
                    }}
                  >
                    Back Home
                  </Typography>
                </div>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
