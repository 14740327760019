import "./App.css";
import { RouterProvider } from "react-router-dom";
import { routerItem } from "./utils/router";
import { fetchUserLocationCookieData } from "./utils/customFunc";
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';
import CookieBottomBar from "./components/cookieBottomBar";
import {isMobile} from 'react-device-detect';
import ReactGA from "react-ga4";

function App() {
  const [cookies, setCookie] = useCookies(['all']);

  ReactGA.initialize("G-NFL975LTSQ");

  const acceptCookie = async() => {
    const cookieData = await fetchUserLocationCookieData()
    setCookie("all",JSON.stringify(cookieData))
  }

  return (
    <div className="App">
      <CookiesProvider>
        <RouterProvider router={routerItem} />
        {
          isMobile ? (
            null
          ): (
            cookies.all == undefined ? (
              <CookieBottomBar acceptAllFunc={() => acceptCookie()}/>
            ):null
          )
        }
      </CookiesProvider>
    </div>
  );
}

export default App;
