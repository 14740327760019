import { Container, Grid } from "@mui/material";
import WhatIsHolisticHealth from "../components/whatIsHolisticHealth";
import Damages from "../components/damages";
import WhyMs from "../components/whyMs";
import ServiceAndBenefits from "../components/serviceAndBenefits";
import JoinWaitListHome from "../components/joinWaitListHome";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const Home:FC = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location])
  
  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={4} sm={8} md={12}>
          <JoinWaitListHome />
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{marginTop:"50px"}}>
        <Container maxWidth="lg">
          <WhatIsHolisticHealth />
        </Container>
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ marginTop: "50px" }}>
        <Container maxWidth="lg">
          <Damages />
        </Container>
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ marginTop: "50px" }}>
        <WhyMs />
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ marginTop: "50px" }}>
        <Container maxWidth="lg">
          <ServiceAndBenefits />
        </Container>
      </Grid>
    </Grid>
  );
};

export default Home;
