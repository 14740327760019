import { Typography, Button } from "@mui/material";
import { FC } from "react";

interface Props {
    acceptAllFunc: () => void    
}

const CookieBottomBar:FC<Props> = ({
    acceptAllFunc
}) => {
  return (
    <div
      style={{
        backgroundColor: "#0F172A",
        position: "sticky",
        bottom: 0,
        paddingTop: "20px",
        paddingBottom: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <Typography
        style={{
          fontFamily: "Red Hat Display",
          color: "#FFFFFF",
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "20px",
        }}
      >
        By clicking “Accept All Cookies”, you agree to the storing of cookies on
        your device to enhance site navigation, analyze site usage, and assist
        in our marketing efforts.
      </Typography>
      <Button
        style={{
          backgroundColor: "#34D399",
          borderRadius: "8px",
          padding: "8px 12px",
          fontFamily: "Red Hat Display",
          color: "#FFFFFF",
          fontSize: "12px",
          fontWeight: 600,
          lineHeight: "20px",
          textTransform: "none",
        }}
        onClick={() => acceptAllFunc()}
      >
        Accept All Cookies
      </Button>
    </div>
  );
};

export default CookieBottomBar;
