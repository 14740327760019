import { Grid, Typography, List, ListItem } from "@mui/material";
import { invisibleDamages, visibleDamages } from "../utils/constants";

const Damages = () => {
  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} columnSpacing={4} rowSpacing={2}>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ display: "flex", textAlign: "start" }}
      >
        <Typography
          sx={{
            fontFamily: "Red Hat Display",
            color: "#0F172A",
            fontSize: { xs: "40px", sm: "40px", md: "52px" },
            fontWeight: 700,
            lineHeight: "125%",
          }}
        >
          How Stress Can Affect
          <br /> You?
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={6}
        style={{ marginTop: "10px", textAlign: "start" }}
      >
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: "#0069FF",
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "150%",
          }}
        >
          Visible Damages
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            listStylePosition: "inside",
          }}
        >
          {visibleDamages.map((e) => (
            <ListItem
              disablePadding
              sx={{
                display: "list-item",
                color: "#475569",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "20px",
                fontFamily: "Red Hat Display",
                marginBottom: "4px",
              }}
            >
              {e}
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid
        item
        xs={4}
        sm={4}
        md={6}
        sx={{ marginTop: "10px", textAlign: "start", alignItems:"center", display:"flex", flexDirection:"column" }}
      >
        <div>
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: "#0069FF",
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "150%",
          }}
        >
          Invisible Damages
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            listStylePosition: "inside",
          }}
        >
          {invisibleDamages.map((e) => (
            <ListItem
              disablePadding
              sx={{
                display: "list-item",
                color: "#475569",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "20px",
                fontFamily: "Red Hat Display",
                marginBottom: "4px",
              }}
            >
              {e}
            </ListItem>
          ))}
        </List>
        </div>
      </Grid>
    </Grid>
  );
};

export default Damages;
