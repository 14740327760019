import { Grid, Typography } from "@mui/material";
import { signUpRoles } from "../utils/constants";
import { FC } from "react";

interface Props {
  selectedRole: string;
  onClick: (e:string) => void
}

const SignUpToggle: FC<Props> = ({ selectedRole, onClick }) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{ width: "fit-content", padding:"4px", borderRadius:"8px",border:"1px solid #E4E4E7" }}
    >
      <Grid
        item
        xs={2}
        sm={4}
        md={6}
        style={{
          backgroundColor:
            selectedRole == signUpRoles[0] ? "#0069FF" : "#FFFFFF",
          borderRadius: "8px",
          padding:"8px 16px",
          cursor:"pointer"
        }}
        onClick={() => onClick("Customer")}
      >
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: selectedRole == signUpRoles[0] ? "#FFFFFF" : "#A1A1AA",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Customer
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        sm={4}
        md={6}
        style={{
          backgroundColor:
            selectedRole == signUpRoles[1] ? "#0069FF" : "#FFFFFF",
          borderRadius: "8px",
          padding:"8px 16px",
          cursor:"pointer"
        }}
        onClick={() => onClick("Consultant")}
      >
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: selectedRole == signUpRoles[1] ? "#FFFFFF" : "#A1A1AA",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "21px",
          }}
        >
          Consultant
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SignUpToggle;
