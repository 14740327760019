import { IICustomCookie } from "./customModels"
import { useCookies } from 'react-cookie';
import { useEffect } from "react";
import ReactGA from "react-ga4";

export const fetchUserLocationCookieData = async (): Promise<IICustomCookie> => {
  let returnData: IICustomCookie = {
    ip: "",
    city: "",
    country: ""
  }
  try {
    const response = await fetch('https://ipapi.co/json/')
    const data = await response.json()

    returnData.ip = data.ip
    returnData.country = data.country_name
    returnData.city = data.city
  } catch (error) { }
  return returnData
}