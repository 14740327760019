import { Container, Grid, Typography, Divider } from "@mui/material";
import { Facebook, Instagram, Linkedin, Logo, Twitter } from "../assets";
import { Link } from "react-router-dom";
import { documentUrlList } from "../utils/constants";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#1E293B", marginTop: "55px" }}>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "60px", marginBottom: "32px" }}
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={4}
            sm={8}
            md={9}
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              textAlign: "start",
            }}
          >
            <img
              src={Logo}
              alt=""
              style={{ width: "163px", height: "50px", marginBottom: "36px" }}
            />
            <Typography
              style={{
                fontFamily: "Red Hat Display",
                color: "#E0E0E0",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "30px",
              }}
            >
              Connects holistic health providers with service seekers worldwide
              <br />
              2810 N Church St PMB 943334 <br />
              Wilmington, Delaware 19802
              <br />
              info@minimumstress.com
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={9}
            md={3}
            style={{ textAlign: "start", lineHeight: "30px" }}
          >
            <a
              href={documentUrlList.termOfUs}
              target="_blank"
              style={{
                fontFamily: "Red Hat Display",
                color: "#E0E0E0",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "150%",
                marginBottom: "8px",
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              Terms Conditions
            </a>
            <Typography
              style={{
                fontFamily: "Red Hat Display",
                color: "#E0E0E0",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "150%",
                marginBottom: "8px",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(documentUrlList.privacyPolicy, "_blank")
              }
            >
              Privacy Policy
            </Typography>
            <Typography
              style={{
                fontFamily: "Red Hat Display",
                color: "#E0E0E0",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "150%",
                cursor: "pointer",
              }}
              onClick={() => window.open(documentUrlList.hipaa, "_blank")}
            >
              HIPAA
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider style={{ backgroundColor: "#7C7C7C" }} />
      <Container
        maxWidth="lg"
        style={{ paddingTop: "32px", paddingBottom: "12px" }}
      >
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={8} md={9} style={{ textAlign: "start" }}>
            <Typography
              style={{
                fontFamily: "Red Hat Display",
                color: "#F8FAFC",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "150%",
              }}
            >
              ©{`${new Date().getFullYear()}`} Minimum Stress. All rights
              reserved.
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={3}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <a
              href="https://www.facebook.com/minimumstressofficial"
              target="_blank"
            >
              <img
                src={Facebook}
                alt="fb_ms"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </a>

            <a
              href="https://www.instagram.com/minimumstressofficial/"
              target="_blank"
            >
              <img
                src={Instagram}
                alt="ins_ms"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </a>
            <a href="https://twitter.com/minimumstress_" target="_blank">
              <img
                src={Twitter}
                alt="tw_ms"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </a>

            <a
              href="https://www.linkedin.com/company/minimumstress"
              target="_blank"
            >
              <img
                src={Linkedin}
                alt="in_ms"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
