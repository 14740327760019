import { Box, Button, Container, Grid, Typography } from "@mui/material";
import {
  Gdpr,
  Hipaa,
  JoinHomeBg,
  JoinWaitlistPattern,
  Ssl,
  WhiteRigthArrow,
} from "../assets";
import { useNavigate } from "react-router-dom";

const JoinWaitListHome = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "#1E293B",
        backgroundImage: `url(${JoinWaitlistPattern})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        component="div"
        sx={{
          backgroundImage: { xs: "", sm: "", md: `url(${JoinHomeBg})` },
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
        }}
      >
        <Container maxWidth="lg">
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} maxWidth={"lg"}>
            <Grid
              item
              xs={4}
              sm={8}
              md={8}
              style={{
                textAlign: "start",
                display: "flex",
                alignItems: "start",
                marginTop: "110px",
                paddingBottom: "32px",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#FF6155",
                  fontSize: { xs: "40px", sm: "40px", md: "72px" },
                  fontWeight: 800,
                  lineHeight: { xs: "44px", sm: "44px", md: "80px" },
                  marginBottom: { xs: "10px", sm: "10px" },
                }}
              >
                Join the Waitlist:
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#F9F9F9",
                  fontSize: { xs: "28px", sm: "28px", md: "50px" },
                  fontWeight: 600,
                  lineHeight: { xs: "30px", sm: "30px", md: "55px" },
                }}
              >
                Elevate Your Mind, Body & Soul
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#FFFFFF",
                  fontSize: { xs: "14px", sm: "14px", md: "18px" },
                  fontWeight: 500,
                  lineHeight: "150%",
                  marginTop: "24px",
                }}
              >
                Are you a holistic health professional or someone seeking
                guidance on your holistic health journey? Be a part of our
                growing community!
              </Typography>

              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#FFFFFF",
                  fontSize: { xs: "14px", sm: "14px", md: "18px" },
                  fontWeight: 600,
                  lineHeight: "150%",
                  marginTop: "24px",
                }}
              >
                🌟 Exclusive "Founding Member" badge for the first 500 members.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#FFFFFF",
                  fontSize: { xs: "14px", sm: "14px", md: "18px" },
                  fontWeight: 600,
                  lineHeight: "150%",
                }}
              >
                🌟 Clients: Enjoy 20% off for an entire year on our diverse
                range of services.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Red Hat Display",
                  color: "#FFFFFF",
                  fontSize: { xs: "14px", sm: "14px", md: "18px" },
                  fontWeight: 600,
                  lineHeight: "150%",
                }}
              >
                🌟 Practitioners: Priority access, unique features, and special
                promotions to amplify your impact.
              </Typography>
              <Typography
                style={{
                  fontFamily: "Red Hat Display",
                  color: "#FFFFFF",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "150%",
                  marginTop: "24px",
                }}
              >
                Minimum Stress is committed to connecting individuals with
                top-tier holistic professionals, fostering a space of growth,
                understanding, and well-being for all.{" "}
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "24px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: "#0069FF",
                    borderRadius: "16px",
                    padding: "12px 22px",
                    fontFamily: "Red Hat Display",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    textTransform: "none",
                  }}
                  onClick={() => navigate("/sign-up")}
                  endIcon={<img src={WhiteRigthArrow} alt="" />}
                >
                  Join Waitlist
                </Button>
                <Button
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "none" },
                    borderColor: "#E2E8F0",
                    borderRadius: "16px",
                    padding: "12px 22px",
                    border: "1px solid #E2E8F0",
                    fontFamily: "Red Hat Display",
                    color: "#FFFFFF",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    textTransform: "none",
                    marginLeft: "16px",
                  }}
                  onClick={() => navigate("/investor-sign-up")}
                >
                  Become Investor
                </Button>
              </div>
              <div
                style={{
                  marginTop: "30px",
                }}
              >
                <img
                  src={Hipaa}
                  alt=""
                  style={{ width: "46px", height: "25px", marginRight: "15px" }}
                />
                <img
                  src={Ssl}
                  alt=""
                  style={{ width: "46px", height: "25px", marginRight: "15px" }}
                />
                <img
                  src={Gdpr}
                  alt=""
                  style={{ width: "86px", height: "28px" }}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default JoinWaitListHome;
