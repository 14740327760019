import { Grid, Typography } from "@mui/material";
import HolisticHealthCard from "./holisticHealthCard";
import { holisticItemList } from "../utils/constants";

const WhatIsHolisticHealth = () => {
  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={2}>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: "32px",
            height: "2px",
            backgroundColor: "#FF6155",
            marginRight: "4px",
          }}
        ></div>
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: "#FF6155",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "125%",
          }}
        >
          Elevate Your Mind, Body & Soul
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        style={{ display: "flex", alignItems: "center", textAlign: "start" }}
      >
        <Typography
          sx={{
            fontFamily: "Red Hat Display",
            color: "#0F172A",
            fontSize: { xs: "40px", sm: "40px", md: "52px" },
            fontWeight: 700,
            lineHeight: "125%",
          }}
        >
          What's <br /> Holistic Health?
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "start",
          justifyContent: "end",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Red Hat Display",
            color: "#475569",
            fontSize: { xs: "15px", sm: "15px", md: "16px" },
            fontWeight: 500,
            lineHeight: "125%",
          }}
        >
          Holistic health is a comprehensive approach to well-being that
          considers the mind, body, and soul. Unlike traditional methods that
          focus solely on physical ailments, holistic health aims to create a
          balance in the body through natural, focused, and personalized care.
          By addressing the root causes and not just symptoms, holistic health
          offers a sustainable path to wellness, ensuring a harmonious and
          enriched life.
        </Typography>
      </Grid>
      {holisticItemList.map((e) => (
        <Grid
          item
          xs={4}
          sm={8}
          md={3}
          style={{
            display: "flex",
            alignItems: "center",
            textAlign: "start",
            justifyContent: "end",
          }}
        >
          <HolisticHealthCard icon={e.icon} text1={e.text1} text2={e.text2} />
        </Grid>
      ))}
    </Grid>
  );
};

export default WhatIsHolisticHealth;
