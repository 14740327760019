import { Container, Grid, Typography } from "@mui/material";
import { Logo, RegisterPatern } from "../assets";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  image:any;
  title:string;
  description:string
}

const InputScreenLeftSide:FC<Props> = ({
  image,
  title,
  description
}) => {
  const navigate = useNavigate()

  return (
    <Container maxWidth="lg" style={{ backgroundColor: "#0069FF", backgroundImage:`url(${RegisterPatern})`,backgroundRepeat:"no-repeat", backgroundPositionY:"bottom" }}>
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        style={{ paddingTop: "32px", paddingBottom:"32px",minHeight:"100vh"}}
      >
        <Grid item xs={4} sm={8} md={12}>
          <img
            src={Logo}
            alt=""
            style={{ alignItems: "start", display: "flex", cursor:"pointer" }}
            onClick={() => navigate("/")}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12} style={{ marginTop: "3%" }}>
          <img
            src={image}
            alt=""
            style={{ width: "500px", height: "500px" }}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={12} style={{textAlign:"center", alignItems:"center", display:"flex", flexDirection:"column", marginTop:"3%"}}>
          <Typography
            style={{
              fontFamily: "Red Hat Display",
              color: "#FFFFFF",
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "150%",
            }}
          >
            {title}
          </Typography>
          <Typography
            style={{
              fontFamily: "Red Hat Display",
              color: "#F8FAFC",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "150%",
              opacity: "0.7",
              marginTop: "10px",
              maxWidth:"500px"
            }}
          >
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InputScreenLeftSide;
