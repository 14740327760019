import { FC, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputScreenLeftSide from "../components/inputScreenLeftSide";
import { AlertWarning, SignUpImg } from "../assets";
import OTPInput from "react-otp-input";
import ThankYouModal from "../components/thankYouModal";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CommonLoading from "../components/commonLoading";
import { IUser } from "../utils/customModels";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const defaultTheme = createTheme();

const Verification: FC = () => {
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [remainingTime, setRemainingTime] = useState<number | null>(90);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    ReactGA.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location])

  useEffect(() => {
    if (remainingTime === 0) {
      setRemainingTime(null);
    }

    if (!remainingTime) return;

    const intervalId = setInterval(() => {
      setRemainingTime(remainingTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [remainingTime]);

  const findUser = async () => {
    try {
      const { data } = await axios.get(
        `https://backend-zl83w.ondigitalocean.app/waitlist/${id}`
      );
      setUser(data);
      if (data.validatedUser) {
        navigate("/");
      }
    } catch (error) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (id === null || id === undefined) {
      navigate("/");
    }
    findUser();
  }, []);

  const submitValidation = async () => {
    setError(null);
    setLoading(true);
    try {
      const validationResponse = await axios.put(
        `https://backend-zl83w.ondigitalocean.app/waitlist/validate`,
        {
          email: user?.email,
          type: user?.type,
          customValidationCode: otp,
        }
      );
      if (validationResponse.data.isRequestSuccessful) {
        setSuccessModal(true);
      } else {
        setError("Code is incorrect");
      }
    } catch (error: any) {
      setError(error?.response?.data?.message || "Code is incorrect");
    }
    setLoading(false);
  };

  const onModalExit = () => {
    setSuccessModal(false);
    navigate("/");
  };

  const sendMailAgain = async() => {
    setError(null);
    setLoading(true);
    try {
      if(user){
        await axios.post(`https://backend-zl83w.ondigitalocean.app/waitlist/resend/${user._id}`)
      }
    } catch (error) {}
    setRemainingTime(120)
    setError(null);
    setLoading(false);
  }

  function secondsToTime(secs:number){
    var h = Math.floor(secs / (60 * 60));
  
    var divisor_for_minutes = secs % (60 * 60);
    var m = Math.floor(divisor_for_minutes / 60);
  
    var divisor_for_seconds = divisor_for_minutes % 60;
    var s = Math.ceil(divisor_for_seconds);
  
    return `${h?`${h}:`:""}${m?`${m}:${s}`:`${s}s`}`
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <ThankYouModal
        isOpen={successModal}
        title={
          user?.type == "investor"
            ? "Thank you for being an investor."
            : "Thank you for joining Minimum Stress!"
        }
        text="We are excited to have you on board. We will keep you updated on the progress and let you know as soon as the platform is ready! Stay tuned!"
        onButtonFunc={() => onModalExit()}
      />
      <CommonLoading loading={loading} />
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <InputScreenLeftSide
            image={SignUpImg}
            title="Expand Your Horizon!"
            description="Discover our innovative platform, offering advanced technology for
          seamless and secure session management Join our community of
          professionals and lead your Holistic Health Practice with confidence
          and convenience"
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={6}
          component={Paper}
          elevation={6}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Box
            sx={{
              my: 8,
              mx: { xs: 6, sm: 6, md: 18 },
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              textAlign: "start",
            }}
          >
            <Typography
              style={{
                fontFamily: "Red Hat Display",
                color: "#1E293B",
                fontSize: "24px",
                fontWeight: 700,
                lineHeight: "26px",
              }}
            >
              Verification Code
            </Typography>
            <Typography
              style={{
                fontFamily: "Red Hat Display",
                color: "#1E293B",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "21px",
                marginTop: "12px",
                marginBottom: "32px",
              }}
            >
              Please enter the verification code send to your <br /> email
              account
            </Typography>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={5}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "58px",
                height: "56px",
                margin: "0px 4px",
                fontFamily: "Red Hat Display",
                fontWeight: 700,
                color: "#0F172A",
                fontSize: "16px",
                borderRadius: "16px",
                border: "1px solid #E9EBED",
              }}
            />
            {error != null ? (
              <div
                style={{
                  display: "flex",
                  marginTop: "8px",
                  alignItems: "center",
                }}
              >
                <img
                  src={AlertWarning}
                  alt=""
                  style={{ width: "16px", height: "16px", marginRight: "4px" }}
                />
                <Typography
                  style={{
                    fontFamily: "Red Hat Display",
                    color: "#FF6155",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                  }}
                >
                  {error}
                </Typography>
              </div>
            ) : null}
            <div
              style={{ display: "flex", marginTop: error ? "16px" : "32px" }}
            >
              <Button
                style={{
                  borderRadius: "16px",
                  border: "1px solid #0069FF",
                  fontFamily: "Red Hat Display",
                  color: "#0069FF",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  textTransform: "none",
                  padding: "10px 32px",
                }}
              >
                Back
              </Button>
              <Button
                style={{
                  borderRadius: "16px",
                  border: "1px solid #0069FF",
                  fontFamily: "Red Hat Display",
                  color: "#FFFFFF",
                  fontSize: "16px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  textTransform: "none",
                  padding: "10px 42px",
                  backgroundColor: otp.length == 5 ? "#0069FF" : "#64748B",
                  marginLeft: "8px",
                }}
                disabled={otp.length != 5 ? true : false}
                onClick={() => submitValidation()}
              >
                Confirm Code
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  fontFamily: "Red Hat Display",
                  color: remainingTime == null ? "#0069FF" : "#94A3B8",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "21px",
                  marginTop: "11px",
                  textTransform: "none",
                }}
                disabled={remainingTime != null ? true :false}
                onClick={sendMailAgain}
              >
                {remainingTime == null
                  ? "Send again"
                  : `Send again (${secondsToTime(remainingTime)})`}
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Verification;
