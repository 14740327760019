import { Grid, Typography, List, ListItem, Button } from "@mui/material";
import {
  BlueConsultant,
  BlueUser,
  WhiteRightDoubleArrow,
  WhiteRigthArrow,
} from "../assets";
import { consultantService, userService } from "../utils/constants";
import { useNavigate } from "react-router-dom";

const ServiceAndBenefits = () => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      rowSpacing={2}
      columnSpacing={4}
    >
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          style={{
            width: "32px",
            height: "2px",
            backgroundColor: "#0069FF",
            marginRight: "4px",
          }}
        ></div>
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: "#0069FF",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "125%",
          }}
        >
          Manage Your Life
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "start",
          marginBottom: "30px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Red Hat Display",
            color: "#0F172A",
            fontSize: { xs: "40px", sm: "40px", md: "52px" },
            fontWeight: 700,
            lineHeight: "125%",
          }}
        >
          Services and Benefits
        </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={6}>
        <div
          style={{
            border: "2px solid #0069FF",
            borderRadius: "16px",
            textAlign: "start",
            padding: "40px",
          }}
        >
          <img src={BlueUser} alt="" />
          <Typography
            style={{
              fontFamily: "Red Hat Display",
              color: "#0F172A",
              fontSize: "22px",
              fontWeight: 700,
              lineHeight: "33px",
              marginTop: "22px",
              marginBottom: "30px",
            }}
          >
            Users
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {userService.map((e) => (
              <ListItem disablePadding>
                <Typography
                  sx={{
                    display: "list-item",
                    color: "#475569",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Red Hat Display",
                    marginBottom: "4px",
                  }}
                >
                  {e}
                </Typography>
              </ListItem>
            ))}
          </List>
          <Button
            style={{
              backgroundColor: "#0069FF",
              borderRadius: "16px",
              padding: "12px 22px",
              fontFamily: "Red Hat Display",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              textTransform: "none",
              marginTop: "20px",
              width: "100%",
            }}
            endIcon={<img src={WhiteRightDoubleArrow} alt="" />}
            onClick={() =>
              navigate("/sign-up", { state: { type: "Customer" } })
            }
          >
            Join Users Waitlist
          </Button>
        </div>
      </Grid>
      <Grid item xs={4} sm={8} md={6}>
        <div
          style={{
            border: "2px solid #0069FF",
            borderRadius: "16px",
            textAlign: "start",
            padding: "40px",
          }}
        >
          <img
            src={BlueConsultant}
            alt=""
            style={{ width: "64px", height: "63px" }}
          />
          <Typography
            style={{
              fontFamily: "Red Hat Display",
              color: "#0F172A",
              fontSize: "22px",
              fontWeight: 700,
              lineHeight: "33px",
              marginTop: "22px",
              marginBottom: "30px",
            }}
          >
            Consultants
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
          >
            {consultantService.map((e) => (
              <ListItem disablePadding>
                <Typography
                  sx={{
                    display: "list-item",
                    color: "#475569",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Red Hat Display",
                    marginBottom: "4px",
                  }}
                >
                  {e}
                </Typography>
              </ListItem>
            ))}
          </List>
          <Button
            style={{
              backgroundColor: "#0069FF",
              borderRadius: "16px",
              padding: "12px 22px",
              fontFamily: "Red Hat Display",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              textTransform: "none",
              marginTop: "20px",
              width: "100%",
            }}
            endIcon={<img src={WhiteRightDoubleArrow} alt="" />}
            onClick={() =>
              navigate("/sign-up", { state: { type: "Consultant" } })
            }
          >
            Join Consultant Waitlist
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ServiceAndBenefits;
