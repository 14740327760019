import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { BlueDna, BlueEye, BlueHearth, BlueSocial } from "../assets";

interface Props {
  icon: any;
  text1: string;
  text2: string;
}

const HolisticHealthCard: FC<Props> = ({ icon, text1 = "", text2 = "" }) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        borderRadius: "20px",
        border: "2px solid #E2E8F0",
        padding: "34px",
        display:"flex"
      }}
    >
      <Grid item xs={4} sm={8} md={12}>
        <img src={icon} alt="" style={{}} />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ marginTop: "16px", marginBottom: "25px" }}
      >
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: "#0F172A",
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "125%",
          }}
        >
          {text1}
        </Typography>
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            color: "#0F172A",
            fontSize: "22px",
            fontWeight: 700,
            lineHeight: "125%",
          }}
        >
          {text2}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HolisticHealthCard;
