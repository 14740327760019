import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import InvestorSignUp from "../pages/investorSignUp";
import Layout from "../components/layout";
import SignUp from "../pages/signUp";
import Verification from "../pages/verification";
import TermsAndConditions from "../pages/termsAndConditions";
import { Logo, MsColoredLogo } from "../assets";

export const routerItem = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout msLogo={Logo}>
        <Home />
      </Layout>
    ),
  },
  {
    path: "/investor-sign-up",
    element: <InvestorSignUp />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/verification/:id",
    element: <Verification />,
  },
]);
