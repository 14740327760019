import { FC } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import {
  Celebrate,
  Close,
  FacebookGrey,
  InstagramGrey,
  LinkedinGrey,
  TwitterGrey,
} from "../assets";
import { useNavigate } from "react-router-dom";

interface Props {
  isOpen: boolean;
  title: string;
  text: string;
  onButtonFunc: () => void;
}

const ThankYouModal: FC<Props> = ({
  isOpen = false,
  title = "",
  text = "",
  onButtonFunc,
}) => {

    const navigate = useNavigate()

  return (
    <Dialog open={isOpen} onClose={onButtonFunc} style={{ width: "auto" }}>
      <DialogContent
        sx={{
          border: "1px solid #DEE2E6",
          boxShadow:
            "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: "fit-content",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection:"row",
            alignItems: "center",
            justifyContent: "space-between",
            width:"100%"
          }}
        >
          <Typography
            style={{
              fontFamily: "Red Hat Display",
              fontWeight: 700,
              fontSize: "18px",
              lineHeight: "25px",
              color: "#0F172A",
            }}
          >
            Thank You
          </Typography>
          <div
            style={{
              padding: "8px",
              backgroundColor: "#F1F5F9",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor:"pointer"
            }}
            onClick={onButtonFunc}
          >
            <img src={Close} alt="" style={{ width: "24px", height: "24px" }} />
          </div>
        </div>
        <Divider style={{ backgroundColor: "#F1F5F9", width:"150%", marginTop:"15px" }} />

        <img src={Celebrate} alt="" width={40} />
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "25px",
            marginBottom: "16px",
            marginTop: "16px",
            color: "#0F172A",
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            marginBottom: "24px",
            color: "#0F172A",
          }}
        >
          {text}
        </Typography>
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "21px",
            marginBottom: "18px",
            color: "#0F172A",
          }}
        >
          Stay tuned!
        </Typography>
        <Divider style={{ backgroundColor: "#F1F5F9", width: "54%" }} />
        <Typography
          style={{
            fontFamily: "Red Hat Display",
            fontWeight: 700,
            fontSize: "12px",
            lineHeight: "17px",
            marginTop: "32px",
            color: "#64748B",
          }}
        >
          Follow Us
        </Typography>
        <div style={{ marginTop: "16px", marginBottom: "32px" }}>
          <img
            src={FacebookGrey}
            alt=""
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
          />
          <img
            src={InstagramGrey}
            alt=""
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
              marginLeft: "40px",
              marginRight: "40px",
            }}
          />
          <img
            src={TwitterGrey}
            alt=""
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
          />
          <img
            src={LinkedinGrey}
            alt=""
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
              marginLeft: "40px",
            }}
          />
        </div>
        <Button
          style={{
            borderRadius: "10px",
            border: "1px solid #0069FF",
            fontFamily: "Red Hat Display",
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: 700,
            lineHeight: "15px",
            textTransform: "none",
            padding: "8px 32px",
            backgroundColor: "#0069FF",
          }}
          onClick={() => navigate("/")}
        >
          Go Back Home
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ThankYouModal;
