import { ReactNode } from "react";
import Footer from "./footer";
import Header from "./header";

interface Props {
  window?: () => Window;
  children: ReactNode;
  msLogo: any;
}

const Layout = (props: Props) => {
  return (
    <>
      <Header msLogo={props.msLogo} />
      {props.children}
      <Footer />
    </>
  );
};

export default Layout;
